var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseDashboard',{attrs:{"indicators":_vm.indicators,"dataSources":_vm.dataSources,"defaultIndicators":_vm.defaultIndicators,"initialIndicator":_vm.initialIndicator,"initialDataSource":_vm.initialDataSource,"initialLocation":_vm.initialLocation,"updateValue":_vm.updateValue,"updateKey":_vm.updateKey,"resetData":_vm.resetData},on:{"swipe":_vm.changeSwipe,"scrollN":_vm.changeScroll},scopedSlots:_vm._u([{key:"section-before-0",fn:function(){return [_vm._t("top-section")]},proxy:true},{key:"section-0",fn:function(ref){
var payload = ref.payload;
var controlIndex = ref.controlIndex;
return [_c('div',{staticClass:"col-md-12"},[_c('base-sub-card',{staticClass:"my-2 shadow-sm",attrs:{"backgroundColor":'#348481'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-weight-bold work-sans text-white"},[_vm._v("Indicator Overview")])]},proxy:true}],null,true)},[[_c('ControlPanelConfiguration',{attrs:{"controlIndex":controlIndex}},[_c('BaseIndicatorOverview',{attrs:{"showTableRelatedIndicator":_vm.showTableRelatedIndicator,"controlPanelProps":payload},on:{"value":_vm.getValue,"key":_vm.getKey,"reset":_vm.getReset}})],1)]],2)],1)]}},{key:"section-1",fn:function(ref){
var payload = ref.payload;
var controlIndex = ref.controlIndex;
return [_c('div',{staticClass:"col-md-12",staticStyle:{"margin-bottom":"4rem"}},[_c('base-sub-card',{staticClass:"my-2 shadow-sm",attrs:{"backgroundColor":'#348481'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-weight-bold work-sans text-white"},[_vm._v("Zonal Analysis")])]},proxy:true}],null,true)},[[_c('LazyLoading',[_c('ControlPanelConfiguration',{attrs:{"controlIndex":controlIndex}},[_c('BaseZonalAnalysisSection',{attrs:{"controlPanelProps":payload}})],1)],1)]],2)],1)]}},{key:"section-2",fn:function(ref){
var payload = ref.payload;
var controlIndex = ref.controlIndex;
return [_c('div',{staticClass:"col-md-12"},[_c('base-sub-card',{attrs:{"backgroundColor":'#348481'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-weight-bold work-sans text-white"},[_vm._v(" Indicator Comparison - By Period/State ")])]},proxy:true}],null,true)},[[_c('LazyLoading',[_c('ControlPanelConfiguration',{attrs:{"controlIndex":controlIndex}},[_c('ICS',{attrs:{"values":payload,"controlIndex":controlIndex}})],1)],1)]],2)],1)]}},{key:"section-3",fn:function(ref){
var payload = ref.payload;
var controlIndex = ref.controlIndex;
return [_c('div',{staticClass:"col-md-12"},[_c('base-sub-card',{staticClass:"my-2 shadow-sm",attrs:{"backgroundColor":'#348481'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-weight-bold work-sans text-white"},[_vm._v("Dataset Comparison")])]},proxy:true}],null,true)},[[_c('LazyLoading',[_c('ControlPanelConfiguration',{attrs:{"controlIndex":controlIndex}},[_c('DataSetComparison',{attrs:{"values":payload,"controlIndex":controlIndex}})],1)],1)]],2)],1)]}},{key:"section-4",fn:function(ref){
var payload = ref.payload;
var controlIndex = ref.controlIndex;
return [_c('div',{staticClass:"col-md-12"},[_c('base-sub-card',{staticClass:"my-2 shadow-sm",attrs:{"backgroundColor":'#348481'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-weight-bold work-sans text-white"},[_vm._v(" Multi-Source Indicator Comparison ")])]},proxy:true}],null,true)},[[_c('div',{staticClass:"dummy-row2 row"},[_vm._l((3),function(n){return [_c('div',{key:n,staticClass:"col-lg-4 col-12"},[_c('div',{staticClass:"comparison-header"},[_vm._v("Comparison ("+_vm._s(n)+")")]),_c('LazyLoading',[_c('ControlPanelConfiguration',{attrs:{"groupIndex":n - 1,"controlIndex":controlIndex}},[_c('MultiSourceComponent',{key:n,attrs:{"values":payload[n - 1]}})],1)],1)],1)]})],2)]],2)],1)]}},{key:"section-5",fn:function(ref){
var payload = ref.payload;
var controlIndex = ref.controlIndex;
return [_c('div',{staticClass:"col-md-12"},[_c('base-sub-card',{staticClass:"my-2 shadow-sm",attrs:{"backgroundColor":'#348481'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-weight-bold work-sans text-white"},[_vm._v("Disaggregation Section")])]},proxy:true}],null,true)},[[_c('LazyLoading',[_c('ControlPanelConfiguration',{attrs:{"controlIndex":controlIndex}},[_c('DynamicSection',{attrs:{"values":payload,"controlIndex":controlIndex}})],1)],1)]],2)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }